'use client'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect, useRef } from 'react'
import { z } from 'zod'
import { usePathname } from 'next/navigation'
import { usePostHog } from 'posthog-js/react'

const envSchema = z.object({
  NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1, 'PostHog API key is required'),
})

const pagePatterns = [
  // Static pages
  { pattern: /^\/home$/, name: 'Home' },
  { pattern: /^\/about$/, name: 'About' },
  { pattern: /^\/courses$/, name: 'Courses' },
  { pattern: /^\/dashboard$/, name: 'Dashboard' },
  { pattern: /^\/profile$/, name: 'Profile' },
  { pattern: /^\/settings$/, name: 'Settings' },
  { pattern: /^\/help$/, name: 'Help' },
  { pattern: /^\/contact$/, name: 'Contact' },
  { pattern: /^\/privacy$/, name: 'Privacy Policy' },
  { pattern: /^\/terms$/, name: 'Terms of Service' },
  { pattern: /^\/refund$/, name: 'Refund Policy' },
  { pattern: /^\/cookies$/, name: 'Cookie Policy' },
  { pattern: /^\/community$/, name: 'Community' },
  { pattern: /^\/blog$/, name: 'Blog' },
  { pattern: /^\/podcast$/, name: 'Podcast' },
  { pattern: /^\/jobs$/, name: 'Jobs' },
  { pattern: /^\/press$/, name: 'Press' },
  { pattern: /^\/events$/, name: 'Events' },
  // Course Pages
  { pattern: /^\/course\/new$/, name: 'New Course' },
  { pattern: /^\/course\/[a-zA-Z0-9_-]+$/, name: 'Course Details' },
  // Onboarding Pages
  { pattern: /^\/onboarding\/basic-information$/, name: 'Basic Information' },
  { pattern: /^\/onboarding\/school-information$/, name: 'School Information' },
  { pattern: /^\/onboarding\/learning-goals$/, name: 'Learning Goals' },
  { pattern: /^\/onboarding\/.*$/, name: 'Onboarding' },
  // Study Pages
  { pattern: /^\/study\/quiz\/[a-zA-Z0-9_-]+$/, name: 'Quiz Study' },
  { pattern: /^\/study\/flashcard\/[a-zA-Z0-9_-]+$/, name: 'Flashcard Study' },
  { pattern: /^\/study\/[a-zA-Z0-9_-]+$/, name: 'Study' },
]

const getPageName = (pathname: string): string => {
  // Find the first matching pattern
  const matchedPattern = pagePatterns.find(({ pattern }) => pattern.test(pathname))
  return matchedPattern?.name || 'Unknown Page'
}

const shouldTrackPath = (pathname: string): boolean => {
  if (pathname.startsWith('/api/')) return false
  if (pathname.startsWith('/ingest/')) return false
  if (pathname.startsWith('/assets/')) return false
  if (/\.(ico|png|jpg|jpeg|svg|gif|webp|js|css|woff|woff2|ttf|eot)$/i.test(pathname)) return false

  return true
}

interface CSPostHogProviderProps {
  children: React.ReactNode
}

export function CSPostHogProvider({ children }: CSPostHogProviderProps) {
  const pathname = usePathname()
  const posthogInstance = usePostHog()
  const pageName = getPageName(pathname)
  const debouncedPageViewTimeoutRef = useRef<number | null>(null)
  const previousPathRef = useRef<string | null>(null)

  useEffect(() => {
    try {
      const { NEXT_PUBLIC_POSTHOG_KEY } = envSchema.parse({
        NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
      })

      posthog.init(NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: "/ingest",
        ui_host: "https://us.posthog.com",
        capture_pageview: false,
        capture_pageleave: false,
        person_profiles: 'always',
        session_recording: {
          maskAllInputs: true,
        },
        autocapture: false,
      })
    } catch (error) {
      console.error('PostHog initialization failed:', error)
    }
  }, [])

  useEffect(() => {
    if (!pathname || !posthogInstance) return

    if (!shouldTrackPath(pathname)) return

    if (previousPathRef.current === pathname) return

    if (debouncedPageViewTimeoutRef.current) {
      window.clearTimeout(debouncedPageViewTimeoutRef.current)
    }

    debouncedPageViewTimeoutRef.current = window.setTimeout(() => {
      const url = window.location.href

      posthogInstance.capture('$pageview', {
        $current_url: new URL(url).pathname,
        page_name: pageName,
      })

      previousPathRef.current = pathname
    }, 300)

    return () => {
      if (debouncedPageViewTimeoutRef.current) {
        window.clearTimeout(debouncedPageViewTimeoutRef.current)
      }
    }
  }, [pathname, posthogInstance, pageName])

  return <PostHogProvider client={posthogInstance}>{children}</PostHogProvider>
}
